import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/reducers";
import { AuthState } from "./types";

export const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isSignedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (
      state: { accessToken: string | null },
      { payload }: PayloadAction<string>
    ) => {
      state.accessToken = payload;
    },
    setRefreshToken: (
      state: { refreshToken: string | null },
      { payload }: PayloadAction<string>
    ) => {
      state.refreshToken = payload;
    },
    setIsSignedIn: (
      state: { isSignedIn: Boolean },
      { payload }: PayloadAction<Boolean>
    ) => {
      state.isSignedIn = payload;
    },
    setAccessTokenAndRefreshToken: (
      state: { accessToken: string | null; refreshToken: string | null },
      { payload }: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
    clearAccessTokenAndRefreshToken: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  setAccessTokenAndRefreshToken,
  setIsSignedIn,
  clearAccessTokenAndRefreshToken,
} = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;
