import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/reducers";
import { ModalState } from "./types";

export const initialState: ModalState = {
  modalVisible: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,

  reducers: {
    changeModalVisible: (state, param) => {
      const { payload } = param;
      state.modalVisible = payload;
    },
  },
});

export const { changeModalVisible } = modalSlice.actions;

export const modalSelector = (state: RootState) => state.modal;
