import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/reducers";
import { AppState } from "./types";

export const initialState: AppState = {
  isModalOpen: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
  },
  // extraReducers: {
  // [get.fulfilled.type]: (state, action) => {},
  // },
});

export const { setModalOpen } = appSlice.actions;

export const appSelector = (state: RootState) => state.app;
