import { useDispatch } from "react-redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { configureStore, Action, Store, AnyAction } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";

import rootReducer, { RootState } from "./reducers";

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};

// Load states from browser storage
const PERSISTED_KEYS: string[] = ["app", "auth"];

// Config
let devTools = false;
let loadedState = {} as RootState;

// Make middleware
let middleware: any[] = [];

// Only in browser.
if (typeof window !== "undefined") {
  middleware.push(save({ states: PERSISTED_KEYS }));
  loadedState = { ...load({ states: PERSISTED_KEYS }) } as RootState;
  devTools = true;
}

// Configure the store
const store: AppStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
  preloadedState: loadedState,
  devTools: devTools,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export default store;
