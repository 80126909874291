import React, { useState } from 'react';
import dummyMobile from 'assets/dummy/dummy-mobile.png';
import slicesIcon from 'assets/dummy/slices.png'; // Import the Slices icon
import walletIcon from 'assets/dummy/wallet-money.png'; // Import the Wallet icon
import flashIcon from 'assets/dummy/flash.png'; // Import the flash icon
import moneyIcon from 'assets/dummy/money-receive.png'; // Import the Money icon
import ForBuyersSlicersImage from 'assets/dummy/ForBuyers_slicers.png';
import ForHomeOwnersImage from 'assets/dummy/Pie_Mobile.png';
import forsliceLogoFooter from 'assets/dummy/forslice_logo_footer.png';
import whatsappIcon from 'assets/dummy/whatsapp.png';
import twitterIcon from 'assets/dummy/twitter.png';
import telegramIcon from 'assets/dummy/telegram.png';

function HomePage() {
  const [activeButton, setActiveButton] = useState('forSlicers');

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <img src={dummyMobile} alt="Mobile Image" className="max-w-full" />

      <section className="py-8 h-[600px]">
        <h2 className="text-4xl font-bold mb-10 text-center font-inter">
          How It Works
        </h2>

        <div className="flex justify-center space-x-2 mb-10">
          <button
            className={`px-8 border rounded-full flex items-center space-x-1 ${
              activeButton === 'forSlicers' ? 'bg-[#FDE8F1]' : 'bg-white'
            }`}
            style={{
              width: '153px', // Updated width
              height: '93px', // Updated height
              top: '2058.46px', // Updated top
              borderRadius: '25px', // Updated border radius
              borderColor: '#FDE8F1',
            }}
            onClick={() => handleButtonClick('forSlicers')}
          >
            <img src={ForBuyersSlicersImage} alt="For Slicers" className="" />
          </button>

          <button
            className={`px-10 border rounded-full flex items-center space-x-2 ${
              activeButton === 'forHomeOwners' ? 'bg-[#FDE8F1]' : 'bg-white'
            }`}
            style={{
              width: '153px', // Updated width
              height: '93px', // Updated height
              top: '2058.46px', // Updated top
              left: '9.09px', // Updated left
              borderRadius: '25px', // Updated border radius
              borderColor: '#FDE8F1',
            }}
            onClick={() => handleButtonClick('forHomeOwners')}
          >
            <img
              src={ForHomeOwnersImage}
              alt="For Home Owners"
              className="h-12"
            />
          </button>
        </div>

        <div className="mt-4 mb-8">
          {activeButton === 'forSlicers' ? (
            <>
              <div className="mb-6 text-left flex mx-14">
                <img src={slicesIcon} alt="Slices Icon" className="h-10 mr-4" />
                <p className="font-inter text-xs font-normal text-gray-400">
                  Each property listed on the platform makes for a Pie. Each Pie
                  is divided into Slices representing a share in the future
                  profit the property will generate.
                </p>
              </div>
              <div className="mb-6 text-left flex mx-14">
                <img src={flashIcon} alt="Flash Icon" className="h-10 mr-4" />
                <p className="font-inter text-xs font-normal text-gray-400">
                  Slicers interested in the property mint an NFT representing
                  their profit share.
                </p>
              </div>
              <div className="mb-6 text-left flex mx-14">
                <img src={walletIcon} alt="Wallet Icon" className="h-10 mr-4" />
                <p className="font-inter text-xs font-normal text-gray-400">
                  Each month the profit generated by the property is distributed
                  amongst all slice holders.
                </p>
              </div>
              <div className="mb-6 text-left flex mx-14">
                <img src={moneyIcon} alt="Money Icon" className="h-10 mr-4" />

                <p className="font-inter text-xs font-normal text-gray-400">
                  Slice holders who want their investment back can sell their
                  Slice (NFT) on Opensea.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="mb-6 text-left flex mx-14">
                <img src={slicesIcon} alt="Slices Icon" className="h-10 mr-4" />

                <p className="font-inter text-xs font-normal text-gray-400">
                  Owners join Forslice by listing their properties. Each
                  property is a Pie representing its future profit.
                </p>
              </div>
              <div className="mb-6 text-left flex mx-14">
                <img src={flashIcon} alt="Flash Icon" className="h-10 mr-4" />

                <p className="font-inter text-xs font-normal text-gray-400">
                  Pies are divided into Slices, each Slice is a unique NFT
                  minted on the Ethereum blockchain.
                </p>
              </div>
              <div className="mb-6 text-left flex mx-14">
                <img src={walletIcon} alt="Wallet Icon" className="h-10 mr-4" />

                <p className="font-inter text-xs font-normal text-gray-400">
                  The initial value of the Slice is based on the projected
                  profit of the property, paying out profit over time.
                </p>
              </div>
              <div className="mb-6 text-left flex mx-14">
                <img src={moneyIcon} alt="Money Icon" className="h-10 mr-4" />

                <p className="font-inter text-xs font-normal text-gray-400">
                  Owners gain immediate return on investment minimizing risk and
                  accessing capital essential to grow their business faster.
                </p>
              </div>
            </>
          )}
        </div>
      </section>

      {/* Footer */}

      <div
        className="bg-black flex justify-between items-center p-8"
        style={{ width: '100%', height: '235px' }}
      >
        <div className="flex flex-col items-left mb-4">
          <img
            src={forsliceLogoFooter}
            alt="Forslice Logo"
            className="mr-4 mb-3"
            style={{ height: '30px' }}
          />
          <p className="text-xxs items-left font-inter text-pink-600">
            The Future of Profit Sharing
          </p>
        </div>

        <div className="mr-2 flex flex-col justify-end space-x-3">
          <div className="mb-4 flex justify-end space-x-3">
            {/* <a href="https://wa.me/41772780370">
              <img
                src={whatsappIcon}
                alt="WhatsApp"
                style={{ height: "20px" }}
              />
            </a>
            <a href="https://twitter.com/forslicedigital">
              <img src={twitterIcon} alt="Twitter" style={{ height: "20px" }} />
            </a> */}
            <a href="https://t.me/forslice_admin">
              <img
                src={telegramIcon}
                alt="Telegram"
                style={{ height: '20px' }}
              />
            </a>
          </div>
          <p className="text-xxxxs font-inter text-white text-white-400">
            All Rights Reserved. Forslice 2023.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
