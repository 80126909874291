import { combineReducers } from "@reduxjs/toolkit";

// App
import { appSlice } from "store/app/reducer";
import { AppState } from "store/app/types";

// Auth
import { authSlice } from "store/auth/reducer";
import { AuthState } from "store/auth/types";

// Modal
import { modalSlice } from "store/modal/reducer";
import { ModalState } from "store/modal/types";

const rootReducer = combineReducers({
  app: appSlice.reducer,
  auth: authSlice.reducer,
  modal: modalSlice.reducer,
});

export type RootState = {
  app: AppState;
  auth: AuthState;
  modal: ModalState;
};

export default rootReducer;
